import * as React from "react";
import AnchorLinkSection from "../../anchor-link-section/anchor-link-section";
import { GetWhoWeAre } from "./company-query";
import { description, title } from "./company-section.module.scss";

const CompanySection = () => {
  const data = GetWhoWeAre();
  const descriptionParts = data.Description.split("\n");
  return (
    <AnchorLinkSection name="who-we-are/" className="section">
      <h2
        data-sal="slide-right"
        data-sal-delay="100"
        data-sal-duration="500"
        className={`section-title ${title}`}
      >
        {data.Title}
      </h2>
      {descriptionParts.map((desc) => (
        <p
          data-sal="slide-right"
          data-sal-delay="100"
          data-sal-duration="800"
          key={desc}
          className={description}
        >
          {desc}
        </p>
      ))}
    </AnchorLinkSection>
  );
};

export default CompanySection;
