import { Link } from "gatsby";
import * as React from "react";
import { useLocaleLink } from "../../../utils/useLocaleLink";
import AnchorLinkSection from "../../anchor-link-section/anchor-link-section";
import Image from "../../image/image";
import { GetServices } from "./services-query";
import {
  section,
  serviceCard,
  servicesContainer,
  serviceTitle,
} from "./services-section.module.scss";
import { GetWhatWeDo } from "./what-we-do-query";

const ServicesSection = () => {
  const data = GetWhatWeDo();
  const services = GetServices();
  return (
    <AnchorLinkSection name="what-we-do/" className={`section ${section}`}>
      <h2
        data-sal="slide-right"
        data-sal-delay="100"
        data-sal-duration="500"
        className="section-title"
      >
        {data.Title}
      </h2>
      <div className={servicesContainer}>
        {services.map((service, index) => (
          <div
            data-sal="slide-right"
            data-sal-delay="100"
            data-sal-duration="1000"
            key={service.Title + index}
            className={serviceCard}
          >
            <Image gatsbyImage={service.Image} alt={service.Title} />
            <h2 className={serviceTitle}>{service.Title}</h2>
            <p>{service.Summary}</p>
          </div>
        ))}
      </div>

      <Link className="more-button" to={useLocaleLink("/what-we-do/")}>
        {data.Button}
      </Link>
    </AnchorLinkSection>
  );
};

export default ServicesSection;
