import { graphql, useStaticQuery } from "gatsby";
import useLocationData from "../../../utils/use-location-data";

export const GetWhatDrivesUs = () => {
  const data = useStaticQuery(graphql`
    query getWhatDrivesUs {
      allStrapiWhatDrivesUs {
        nodes {
          locale
          Title
          Description
        }
      }
    }
  `);
  return useLocationData(data.allStrapiWhatDrivesUs);
};
