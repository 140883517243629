import { graphql, useStaticQuery } from "gatsby";
import useLocationData from "../../../utils/use-location-data";

export const GetTeamSection = () => {
  const data = useStaticQuery(graphql`
    query getTeamSection {
      allStrapiOurTeam {
        nodes {
          locale
          Button
          Description
          Title
        }
      }
    }
  `);
  return useLocationData(data.allStrapiOurTeam);
};
