// import { graphql } from "gatsby";
import * as React from "react";
import CompanySection from "../components/sections/company-section/company-section";
import HomeSection from "../components/sections/home-section/home-section";
import PartnersSection from "../components/sections/partners-section/partners-section";
import ServicesSection from "../components/sections/services-section/services-section";
import TeamSection from "../components/sections/team-section/team-section";
import VisionSection from "../components/sections/vision-section/vision-section";
import DownloadSection from "../components/sections/download-section/download-section";


const IndexPage = () => {
  return (
    <>
      <HomeSection />
      <VisionSection />
      <CompanySection />
      <ServicesSection />
      <TeamSection />
      <DownloadSection />
      <PartnersSection />
    </>
  );
};

export default IndexPage;
