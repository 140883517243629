// extracted by mini-css-extract-plugin
export var dBlock = "team-section-module--d-block--b906f";
export var dFlex = "team-section-module--d-flex--905e6";
export var dInline = "team-section-module--d-inline--5caf2";
export var dLgBlock = "team-section-module--d-lg-block--91ef7";
export var dLgFlex = "team-section-module--d-lg-flex--25a23";
export var dLgInline = "team-section-module--d-lg-inline--a5f6c";
export var dLgNone = "team-section-module--d-lg-none--39c72";
export var dMdBlock = "team-section-module--d-md-block--81828";
export var dMdFlex = "team-section-module--d-md-flex--d8777";
export var dMdNone = "team-section-module--d-md-none--3c853";
export var dNone = "team-section-module--d-none--536c7";
export var description = "team-section-module--description--cf9a0";
export var employeeCard = "team-section-module--employeeCard--18053";
export var employeeTitle = "team-section-module--employeeTitle--d8d2b";
export var employeesContainer = "team-section-module--employeesContainer--d380e";
export var teamSection = "team-section-module--teamSection--0c793";
export var title = "team-section-module--title--ac87c";