import * as React from "react";
import AnchorLinkSection from "../../anchor-link-section/anchor-link-section";
import { GetPartnersSection } from "./partners-section.query";
import Carousel from "../../../components/carousel-content/carousel";
import {
  carousel,
  title,
  partnersSection,
} from "./partners-section.module.scss";

const PartnersSection = () => {
  const data = GetPartnersSection();
  return (
    <AnchorLinkSection name="partners/" className={`${partnersSection}`}>
      <h2
        data-sal="slide-right"
        data-sal-delay="100"
        data-sal-duration="500"
        className={`section-title ${title}`}
      >
        {data.Title}
      </h2>
      <p
        data-sal="slide-right"
        data-sal-delay="100"
        data-sal-duration="1000"
        className="section-description"
      >
        {data.Description}
      </p>
      <div data-sal="slide-right" data-sal-delay="100" data-sal-duration="500">
        <Carousel className={carousel} items={data.Partners} />
      </div>
    </AnchorLinkSection>
  );
};

export default PartnersSection;
