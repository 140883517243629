import { graphql, useStaticQuery } from "gatsby";
import useLocationData from "../../../utils/use-location-data";

export const GetWhoWeAre = () => {
  const data = useStaticQuery(graphql`
    query getWhoWeAre {
      allStrapiWhoWeAre {
        nodes {
          locale
          Title
          Description
        }
      }
    }
  `);
  return useLocationData(data.allStrapiWhoWeAre);
};
