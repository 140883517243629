// extracted by mini-css-extract-plugin
export var buttonNext = "carousel-button-module--buttonNext--d76d4";
export var buttonPrev = "carousel-button-module--buttonPrev--66bb4";
export var carouselButton = "carousel-button-module--carouselButton--65838";
export var dBlock = "carousel-button-module--d-block--db172";
export var dFlex = "carousel-button-module--d-flex--c4fc0";
export var dInline = "carousel-button-module--d-inline--e5d9c";
export var dLgBlock = "carousel-button-module--d-lg-block--aab7f";
export var dLgFlex = "carousel-button-module--d-lg-flex--8137f";
export var dLgInline = "carousel-button-module--d-lg-inline--569f7";
export var dLgNone = "carousel-button-module--d-lg-none--13b97";
export var dMdBlock = "carousel-button-module--d-md-block--61dbe";
export var dMdFlex = "carousel-button-module--d-md-flex--60631";
export var dMdNone = "carousel-button-module--d-md-none--120d3";
export var dNone = "carousel-button-module--d-none--84170";