import * as React from "react";
import AnchorLinkSection from "../../anchor-link-section/anchor-link-section";
import { GetWhatDrivesUs } from "./vision-query";
import { description, title } from "./vision-section.module.scss";

const VisionSection = () => {
  const data = GetWhatDrivesUs();
  const descriptionParts = data.Description.split("\n");
  return (
    <AnchorLinkSection name="what-drives-us/" className="section">
      <h2
        data-sal="slide-right"
        data-sal-delay="100"
        data-sal-duration="500"
        className={`section-title ${title}`}
      >
        {data.Title}
      </h2>
      {descriptionParts.map((desc, index) => (
        <p
          data-sal="slide-right"
          data-sal-delay="100"
          data-sal-duration="800"
          key={index}
          className={description}
        >
          {desc}
        </p>
      ))}
    </AnchorLinkSection>
  );
};

export default VisionSection;
