import * as React from "react";
import { useEffect } from "react";
import ReactTouchEvents from "react-touch-events";
import Image from "../image/image";
import CarouselButton from "./carousel-button/carousel-button";
import {
  buttonNext,
  buttonPrev,
} from "./carousel-button/carousel-button.module.scss";
import {
  anchorActive,
  anchors,
  carouselButton,
  heroImage,
  heroLogo,
  heroItem,
  heroSlider,
  noTransition,
  sliderWrapper,
  title,
  titleContainer,
} from "./hero-carousel.module.scss";

const offset = 1;
let currentSlide = offset;
let stopInterval = false;

const HeroSlider = ({ heroes, logoIcon, hideControls = false }) => {
  const list = heroes.slice(-offset);
  list.push(...heroes);
  list.push(...heroes.slice(0, offset));
  const [transform, setTransform] = React.useState(
    `translateX(-${currentSlide * 100}%)`,
  );
  const [canTransition, setCanTransition] = React.useState(true);
  const [items] = React.useState(list);

  const setActiveSlide = (index, transition = true) => {
    currentSlide = index;
    setCanTransition(transition);
    setTransform(`translateX(-${currentSlide * 100}%)`);
    if (!transition) {
      setTimeout(() => {
        setCanTransition(true);
      }, 100);
    }
  };

  const changeItem = (index) => {
    if (index > heroes.length + offset - 1) {
      setActiveSlide(offset - 1, false);
      setTimeout(() => {
        setActiveSlide(offset);
      }, 100);
      return;
    }
    if (index < offset) {
      setActiveSlide(heroes.length + offset, false);
      setTimeout(() => {
        setActiveSlide(heroes.length + offset - 1);
      }, 100);
      return;
    }
    setActiveSlide(index);
  };

  const onPrevItem = (event) => {
    if (event) {
      event.stopPropagation();
    }
    stopInterval = true;
    changeItem(currentSlide - 1);
  };

  const onNextItem = (event) => {
    if (event) {
      event.stopPropagation();
    }
    stopInterval = true;
    changeItem(currentSlide + 1);
  };

  const handleSwipe = (direction) => {
    stopInterval = true;
    if (direction === "left") {
      onNextItem();
    }
    if (direction === "right") {
      onPrevItem();
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (stopInterval) {
        clearInterval(interval);
        return;
      }
      changeItem(currentSlide + 1);
    }, 10000);
    setActiveSlide(offset, false);
  }, []);

  return (
    <ReactTouchEvents onSwipe={(_, direction) => handleSwipe(direction)}>
      <div className={sliderWrapper}>
        {!hideControls && (
          <CarouselButton
            onClick={(event) => onPrevItem(event)}
            className={`${carouselButton} ${buttonPrev}`}
          />
        )}
        <div
          className={`${heroSlider} ${!canTransition && noTransition}`}
          style={{ transform }}
        >
          {items.map((hero, index) => (
            <div key={hero.Title + index} className={heroItem}>
              <div className={titleContainer}>
                <h3 className={title}>{hero.Title}</h3>
                {/* {hero.Subtitle?.length ? (
                  <h2 className={subtitle}>{hero.Subtitle}</h2>
                ) : null} */}
              </div>
              <Image
                gatsbyImage={hero.Image}
                loading={index !== offset ? "lazy" : "eager"}
                alt={hero.Image?.alternativeText ?? hero.Title}
                className={heroImage}
              ></Image>
              <Image
                gatsbyImage={logoIcon}
                loading={index !== offset ? "lazy" : "eager"}
                alt={hero.Image?.alternativeText ?? hero.Title}
                className={heroLogo}
              ></Image>
            </div>
          ))}
        </div>
        {!hideControls && (
          <div className={anchors}>
            {heroes.map((_, i) => (
              <button
                className={currentSlide - offset === i ? anchorActive : ""}
                key={`anchor-${i}`}
                aria-label={`Anchor image ${i + 1}`}
                onClick={() => changeItem(i + offset)}
              ></button>
            ))}
          </div>
        )}
        {!hideControls && (
          <CarouselButton
            onClick={(event) => onNextItem(event)}
            className={`${carouselButton} ${buttonNext}`}
          />
        )}
      </div>
    </ReactTouchEvents>
  );
};

export default HeroSlider;
