// extracted by mini-css-extract-plugin
export var carousel = "partners-section-module--carousel--92229";
export var dBlock = "partners-section-module--d-block--110d0";
export var dFlex = "partners-section-module--d-flex--f3aca";
export var dInline = "partners-section-module--d-inline--91bfb";
export var dLgBlock = "partners-section-module--d-lg-block--41d43";
export var dLgFlex = "partners-section-module--d-lg-flex--23258";
export var dLgInline = "partners-section-module--d-lg-inline--c5133";
export var dLgNone = "partners-section-module--d-lg-none--6cc13";
export var dMdBlock = "partners-section-module--d-md-block--96306";
export var dMdFlex = "partners-section-module--d-md-flex--194ae";
export var dMdNone = "partners-section-module--d-md-none--1ef3d";
export var dNone = "partners-section-module--d-none--fc75a";
export var partnersSection = "partners-section-module--partnersSection--daa8e";
export var title = "partners-section-module--title--c8e71";