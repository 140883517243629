import * as React from "react";
import { Link } from "gatsby";
import AnchorLinkSection from "../../anchor-link-section/anchor-link-section";
import { GetDownloadSection } from "./download-section.query";
import Image from "../../image/image";

import {
  dlBtn,
  title,
  downloadSection,
  download
} from "./download-section.module.scss";

const DownloadSection = () => {
  const data = GetDownloadSection();
  console.log(data);
  return (
    <AnchorLinkSection name="download/" className={`${downloadSection}`}>
      <h2
        data-sal="slide-right"
        data-sal-delay="100"
        data-sal-duration="500"
        className={`section-title ${title}`}
      >
        {data.Title}
      </h2>
      <p
        data-sal="slide-right"
        data-sal-delay="100"
        data-sal-duration="1000"
        className="section-description"
      >
        {data.Description}
      </p>
      <div className={download} data-sal="slide-right" data-sal-delay="100" data-sal-duration="500">
        <Image gatsbyImage={data.Image} alt={data.Title} />
        <a className={dlBtn} href={data.File.url} target="__blank">
          {data.Button}
        </a>
      </div>
    </AnchorLinkSection>
  );
};

export default DownloadSection;
