import { graphql, useStaticQuery } from "gatsby";
import useLocationData from "../../../utils/use-location-data";

export const GetServices = () => {
  const data = useStaticQuery(graphql`
    query getServices {
      allStrapiService(sort: { order: ASC, fields: Position }) {
        nodes {
          locale
          Summary
          Title
          Image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `);
  return useLocationData(data.allStrapiService);
};
