import { graphql, useStaticQuery } from "gatsby";
import useLocationData from "../../../utils/use-location-data";

export const GetPartnersSection = () => {
  const data = useStaticQuery(graphql`
    query GetPartnersSection {
      allStrapiPartners {
        nodes {
          locale
          Title
          Description
          Partners {
            Name
            Link
            Image {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  `);
  return useLocationData(data.allStrapiPartners);
};
