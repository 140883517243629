import Image from "../image/image";
import React from "react";
import { cItem } from "./carousel.module.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Carousel = ({ items }) => {
  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
    ],
  };

  return (
    <div>
      <Slider {...settings}>
        {items.map((item) => (
          <div key={item.Link}>
            <a
              href={item.Link}
              className={`${cItem}`}
              target="_blank"
              rel="noreferrer"
            >
              <Image
                gatsbyImage={item.Image}
                loading="lazy"
                objectFit="contain"
              ></Image>
            </a>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Carousel;
