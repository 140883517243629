// extracted by mini-css-extract-plugin
export var dBlock = "download-section-module--d-block--2cb1d";
export var dFlex = "download-section-module--d-flex--3b2a5";
export var dInline = "download-section-module--d-inline--6f1ba";
export var dLgBlock = "download-section-module--d-lg-block--d46ea";
export var dLgFlex = "download-section-module--d-lg-flex--cf58d";
export var dLgInline = "download-section-module--d-lg-inline--b74f6";
export var dLgNone = "download-section-module--d-lg-none--3c3ae";
export var dMdBlock = "download-section-module--d-md-block--09ab4";
export var dMdFlex = "download-section-module--d-md-flex--88b58";
export var dMdNone = "download-section-module--d-md-none--36a18";
export var dNone = "download-section-module--d-none--214e4";
export var dlBtn = "download-section-module--dlBtn--c89f0";
export var download = "download-section-module--download--9570f";
export var downloadSection = "download-section-module--downloadSection--cf333";