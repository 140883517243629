import { graphql, useStaticQuery } from "gatsby";
import useLocationData from "../../../utils/use-location-data";

export const GetHome = () => {
  const data = useStaticQuery(graphql`
    query getHome {
      allStrapiHome {
        nodes {
          locale
          Slider {
            Image {
              url
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            Title
          }
          LogoIcon {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100)
              }
            }
          }
        }
      }
    }
  `);
  return useLocationData(data.allStrapiHome);
};
