import * as React from "react";
import AnchorLinkSection from "../../anchor-link-section/anchor-link-section";
import HeroSlider from "../../hero-carousel/hero-carousel";
import { GetHome } from "./home-query";
import { homeSection } from "./home-section.module.scss";

const HomeSection = () => {
  const data = GetHome();
  return (
    <AnchorLinkSection className={`section ${homeSection}`}>
      <HeroSlider heroes={data.Slider} logoIcon={data.LogoIcon}></HeroSlider>
    </AnchorLinkSection>
  );
};

export default HomeSection;
