import { graphql, useStaticQuery } from "gatsby";
import useLocationData from "../../../utils/use-location-data";

export const GetTeam = () => {
  const data = useStaticQuery(graphql`
    query getTeam {
      allStrapiEmployee(sort: { order: ASC, fields: DisplayPosition }) {
        nodes {
          locale
          Position
          Name
          Description
          Image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `);
  return useLocationData(data.allStrapiEmployee);
};
