import * as React from "react";
import AnchorLinkSection from "../../anchor-link-section/anchor-link-section";
import Image from "../../image/image";
import { GetTeam } from "./team-query";
import { GetTeamSection } from "./team-section-query";
import {
  description,
  employeeCard,
  employeesContainer,
  employeeTitle,
  title,
  teamSection,
} from "./team-section.module.scss";

const TeamSection = () => {
  const data = GetTeamSection();
  let employees = [GetTeam()];
  if (!Array.isArray(employees)) {
    employees = [employees];
  }

  return (
    <AnchorLinkSection name="our-team/" className={`${teamSection}`}>
      <h2
        data-sal="slide-right"
        data-sal-delay="100"
        data-sal-duration="500"
        className={`section-title ${title}`}
      >
        {data.Title}
      </h2>
      <p
        data-sal="slide-right"
        data-sal-delay="100"
        data-sal-duration="1000"
        className="section-description"
      >
        {data.Description}
      </p>
      <div className={employeesContainer}>
        {employees.map((employee, index) => (
          <div
            data-sal="fade"
            data-sal-delay="100"
            data-sal-duration="800"
            key={employee.Name + index}
            className={employeeCard}
          >
            <Image gatsbyImage={employee.Image} alt={employee.Name} />
            <div className={employeeTitle}>
              <h4>{employee.Name}</h4>
              <h5>{employee.Position}</h5>
            </div>
            <p className={description}>{employee.Description}</p>
          </div>
        ))}
      </div>
    </AnchorLinkSection>
  );
};

export default TeamSection;
