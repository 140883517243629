// extracted by mini-css-extract-plugin
export var dBlock = "services-section-module--d-block--01994";
export var dFlex = "services-section-module--d-flex--ed199";
export var dInline = "services-section-module--d-inline--2409b";
export var dLgBlock = "services-section-module--d-lg-block--f4dba";
export var dLgFlex = "services-section-module--d-lg-flex--6867d";
export var dLgInline = "services-section-module--d-lg-inline--864fb";
export var dLgNone = "services-section-module--d-lg-none--27f5b";
export var dMdBlock = "services-section-module--d-md-block--5d98e";
export var dMdFlex = "services-section-module--d-md-flex--77a38";
export var dMdNone = "services-section-module--d-md-none--ceb24";
export var dNone = "services-section-module--d-none--b69b7";
export var section = "services-section-module--section--767b2";
export var serviceCard = "services-section-module--serviceCard--3cd95";
export var serviceTitle = "services-section-module--serviceTitle--ed56a";
export var servicesContainer = "services-section-module--servicesContainer--09002";