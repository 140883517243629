import { graphql, useStaticQuery } from "gatsby";
import useLocationData from "../../../utils/use-location-data";

export const GetDownloadSection = () => {
  const data = useStaticQuery(graphql`
    query GetDownloadSection {
      allStrapiDownload {
        nodes {
          locale
          Title
          Description
          Button
          File {
            url
          }
          Image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `);
  return useLocationData(data.allStrapiDownload);
};
