import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useInView } from "react-intersection-observer";
import { defaultLocale } from "../../../config/i18n";
import { LocaleContext } from "../layout/layout";

const AnchorLinkSection = ({
  intersectionChange,
  name,
  children,
  ...remainingProps
}) => {
  const anchorStyles = {
    height: "1px",
    width: "100%",
    position: "absolute",
    left: "0",
  };
  const { locale } = useContext(LocaleContext);
  const [canChange, setCanChange] = useState(false);

  const onIntersectionChange = (intersecting) => {
    if (intersectionChange) {
      intersectionChange(intersecting);
    }
  };
  const handleViewChange = (inView) => {
    if (inView && canChange) {
      const localePrefix = locale === defaultLocale ? "" : "/" + locale;
      const newHash = localePrefix + (name ? `/#${name}` : "/");
      window.history.replaceState({}, document.title, newHash);
    }
    onIntersectionChange(inView);
  };
  const [refStart] = useInView({ onChange: handleViewChange });
  const [refEnd] = useInView({ onChange: handleViewChange });

  useEffect(() => {
    setCanChange(true);
  }, []);

  return (
    <section id={name} style={{ position: "relative" }} {...remainingProps}>
      <div
        ref={refStart}
        style={{ ...anchorStyles, top: "min(50vh, 50%)" }}
      ></div>
      {children}
      <div
        ref={refEnd}
        style={{ ...anchorStyles, bottom: "min(50vh, 50%)" }}
      ></div>
    </section>
  );
};

export default AnchorLinkSection;
