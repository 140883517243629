// extracted by mini-css-extract-plugin
export var activecItem = "carousel-module--activecItem--80110";
export var cItem = "carousel-module--cItem--4886b";
export var cItemDescription = "carousel-module--cItemDescription--98fc0";
export var dBlock = "carousel-module--d-block--b80ee";
export var dFlex = "carousel-module--d-flex--1ad32";
export var dInline = "carousel-module--d-inline--87708";
export var dLgBlock = "carousel-module--d-lg-block--9f1d2";
export var dLgFlex = "carousel-module--d-lg-flex--afc34";
export var dLgInline = "carousel-module--d-lg-inline--74e30";
export var dLgNone = "carousel-module--d-lg-none--a1a71";
export var dMdBlock = "carousel-module--d-md-block--1709c";
export var dMdFlex = "carousel-module--d-md-flex--282c4";
export var dMdNone = "carousel-module--d-md-none--4294f";
export var dNone = "carousel-module--d-none--2b5a3";
export var nextcItem = "carousel-module--nextcItem--0c6ec";
export var prevcItem = "carousel-module--prevcItem--af46f";