import { carouselButton } from "./carousel-button.module.scss";

import React from "react";

const CarouselButton = ({ onClick, className }) => {
  return (
    <button onClick={onClick} className={[className, carouselButton].join(" ")}>
      <span></span>
    </button>
  );
};

export default CarouselButton;
